<template>
  <v-row dense class="fill-height full-width" v-if="$can('can_see_map')">
    <v-col cols="12 py-0 d-flex" id="map-container"></v-col>
  </v-row>
</template>
<script>
import { loadModules } from 'esri-loader'

/* Mixins */
import mapLocationMixin from '@/mixins/map-location.mixin'

export default {
  name: 'MapPage',
  data: () => ({
    id: null,
    graphic: null,
    lng: -105,
    lat: 43.95,
    zoom: 2.9,
    themeMode: 'dark-gray-vector'
  }),
  mixins: [mapLocationMixin],
  methods: {
    loadMap () {
      return loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/config',
        'esri/widgets/Search',
        'esri/widgets/Fullscreen',
        'esri/widgets/BasemapToggle'
      ], {
        css: true
      })
        .then(([ArcGISMap, MapView, Graphic, esriConfig]) => {
          this.graphic = Graphic
          esriConfig.apiKey = process.env.ESRI_MAP_API_KEY
          const map = new ArcGISMap({
            basemap: this.themeMode
          })
          this.view = new MapView({
            map: map,
            center: [this.lng, this.lat],
            zoom: this.zoom,
            container: document.getElementById('map-container')
          })
        })
    },
    configMap () {
      if (!this.id) return

      const map = this.$firestore.collection('map').doc(this.id)

      map.onSnapshot((map) => {
        if (!map.exists) return

        const data = map.data()

        if (!data.data) return

        this.handleAction(JSON.parse(data.data))
      })
    },
    handleAction (action) {
      const method = action.method

      if (!method) return

      switch (method) {
        case 'setLocations':
          this._setLocations(this.graphic, action.held)
          break
        case 'clearLocation':
          this._clearLocation()
          break
        default:
          break
      }
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.loadMap().then(() => {
      this.configMap()
    })
  }
}
</script>
