import { mapGetters } from 'vuex'
import store from '@/store'
import { loadModules } from 'esri-loader'

export default {
  data: () => ({
    map: null,
    what3words: '',
    graphicData: '',
    rebid: 1,
    geocode: {
      Address: '',
      Addr_type: '',
      Match_addr: '',
      AddNum: '',
      Postal: '',
      Region: ''
    },
    mapFilters: {
      parks: null,
      earthquakes: null,
      airports: null,
      wildfires: null,
      railLines: null,
      PSAP911: null,
      policeDepartments: null,
      fireStations: null,
      hospitals: null
    }
  }),
  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('settings', ['getGlobalSetting'])
  },
  watch: {
    what3words (val) {
      if (val) this.replacePointGraphic()
    },
    geocode (val) {
      if (val) this.replacePointGraphic()
    }
  },
  methods: {
    setLocations (Graphic, held) {
      const lat = held.lat
      const lng = held.lng
      if (!lat || !lng) return

      this._setLocations(Graphic, held)

      this.sendRequest()

      this._sendToFirestore({ method: 'setLocations', held })
    },
    setRadiusAroundLocationGraphic (held) {
      loadModules(['esri/geometry/Circle', 'esri/Graphic']).then(
        ([Circle, Graphic]) => {
          const lng = parseFloat(held.lng)
          const lat = parseFloat(held.lat)
          if (!lat || !lng) return

          const circleRadius = held.meters ?? 100
          const circleConfidence = held.confidence ?? 100
          const confidencePercentage = 1 - circleConfidence / 100

          const outerCircle = new Circle({
            center: [lng, lat],
            numberOfPoints: 1000,
            geodesic: true,
            radius: circleRadius + circleRadius * confidencePercentage,
            radiusUnit: 'meters'
          })

          const innerCircle = new Circle({
            center: [lng, lat],
            numberOfPoints: 1000,
            geodesic: true,
            radius: circleRadius - circleRadius * confidencePercentage,
            radiusUnit: 'meters'
          })
          const middleCircle = new Circle({
            center: [lng, lat],
            geodesic: true,
            numberOfPoints: 1000,
            radius: circleRadius,
            radiusUnit: 'meters'
          })

          const outerCircleGraphic = new Graphic({
            geometry: outerCircle,
            symbol: {
              type: 'simple-fill',
              color: [171, 196, 255, 0.1],
              outline: {
                width: 1,
                color: '#173657'
              }
            }
          })
          const innerCircleGraphic = new Graphic({
            geometry: innerCircle,
            symbol: {
              type: 'simple-fill',
              color: [255, 166, 158, 0.1],
              outline: {
                width: 1,
                color: '#c03e41'
              }
            }
          })
          const middleCircleGraphic = new Graphic({
            geometry: middleCircle,
            symbol: {
              type: 'simple-fill',
              color: [82, 183, 136, 0.1],
              outline: {
                width: 1,
                color: '#2d835d'
              }
            }
          })

          this.view.graphics.addMany([
            outerCircleGraphic,
            innerCircleGraphic,
            middleCircleGraphic
          ])
        }
      )
    },
    _setLocations (Graphic, held) {
      const lng = parseFloat(held.lng)
      const lat = parseFloat(held.lat)
      this.view.goTo({
        center: [lng, lat],
        zoom: 18
      })
      const pointGraphic = this.createNewPointGraphic(Graphic, held)
      this.addGraphicOpenWindow(pointGraphic)
      this.numerateTextGraphic(Graphic, held)
      this.geoCodeURL(this.Point, this.SpatialReference)
    },
    numerateTextGraphic (Graphic, held) {
      const lng = parseFloat(held.lng)
      const lat = parseFloat(held.lat)
      const createdGraphic = new Graphic({
        geometry: this.point(lng, lat),
        symbol: this.textSymbol()
      })
      this.view.graphics.addMany([createdGraphic])

      this.rebid += 1
    },
    createNewPointGraphic (Graphic, held, what3words = '', geocode = '') {
      const lng = parseFloat(held.lng)
      const lat = parseFloat(held.lat)
      const createdGraphic = new Graphic({
        geometry: this.point(lng, lat),
        symbol: this.markerSymbol(),
        popupTemplate: {
          content: `
      <div class="esri-popup__content-wrapper map-custom-style">
        <div class="px-3">
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 16px;">Geocoded address:</h5>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Address</h5>
                <span class="text-textGray3" style="font-size: 14px;">${
                  geocode.Address
                }</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Addr type</h5>
                <span class="text-textGray3" style="font-size: 14px;">${
                  geocode.Addr_type
                }</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row my-1 px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Match addr</h5>
                <span class="text-textGray3" style="font-size: 14px;">${
                  geocode.Match_addr
                }</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Add Num</h5>
                <span class="text-textGray3" style="font-size: 14px;">${
                  geocode.AddNum
                }</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Postal</h5>
                <span class="text-textGray3" style="font-size: 14px;">${
                  geocode.Postal
                }</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Region</h5>
                <span class="text-textGray3" style="font-size: 14px;">${
                  geocode.Region
                }</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">What 3 Words</h5>
                <span class="text-textGray3" style="font-size: 14px;">${what3words}</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">DMS</h5>
                <span class="text-textGray3" style="font-size: 14px;">${this.dmsFormat(
                  lat,
                  lng
                )}</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Latitude</h5>
                <span class="text-textGray3" style="font-size: 14px;">${lat}</span>
              </div>
            </div>
          </div>
          <div class="v-row v-row--no-gutters d-flex flex-row esri-map-row px-0">
            <div class="v-col v-col-12">
              <div class="d-flex flex-column">
                <h5 class="text-textGray4 font-weight-bold" style="font-size: 14px;">Longitude</h5>
                <span class="text-textGray3" style="font-size: 14px;">${lng}</span>
              </div>
            </div>
          </div>
        </div>
      </div>`
        }
      })
      this.graphicData = createdGraphic
      return createdGraphic
    },
    clearLocation () {
      this._clearLocation()

      this._sendToFirestore({ method: 'clearLocation' })
    },
    _clearLocation () {
      this.view.goTo({
        center: [this.lng, this.lat],
        zoom: this.zoom
      })
      this.view.graphics.removeAll()
      this.view.popup.close()
      this.rebid = 1
    },
    markerSymbol () {
      return {
        type: 'simple-marker',
        color: [226, 119, 40],
        outline: {
          color: [255, 255, 255],
          width: 2
        }
      }
    },
    point (lng, lat) {
      return {
        type: 'point',
        longitude: lng,
        latitude: lat
      }
    },
    textSymbol () {
      return {
        type: 'text',
        color: 'white',
        haloColor: 'black',
        haloSize: '30px',
        text: this.rebid,
        xoffset: 0,
        yoffset: -30,
        font: {
          size: 18,
          weight: 'bold'
        }
      }
    },
    addGraphicOpenWindow (pointGraphic) {
      this.view.graphics.addMany([pointGraphic])
      this.view.popup.open({
        location: pointGraphic.geometry,
        features: [pointGraphic]
      })
    },
    replacePointGraphic () {
      const pointGraphic = this.createNewPointGraphic(
        this.graphic,
        this.heldGetter,
        this.what3words,
        this.geocode
      )
      this.addGraphicOpenWindow(pointGraphic)
    },
    addSearch (Search) {
      const search = new Search({
        view: this.view
      })
      this.view.ui.add(search, 'top-right')
    },
    fullScreen (Fullscreen) {
      const fullscreen = new Fullscreen({
        view: this.view
      })
      this.view.ui.add(fullscreen, 'top-left')
    },
    mapToggle (BasemapToggle) {
      const basemapToggle = new BasemapToggle({
        view: this.view,
        nextBasemap: 'satellite'
      })

      this.view.ui.add(basemapToggle, 'bottom-right')
    },
    geoCodeURL (Point, SpatialReference) {
      const locatorUrl =
        'http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer'
      const clickedPoint = new Point({
        latitude: parseFloat(this.heldGetter.lat),
        longitude: parseFloat(this.heldGetter.lng),
        spatialReference: new SpatialReference({ wkid: 4326 })
      })
      this.locator
        .locationToAddress(locatorUrl, {
          location: clickedPoint
        })
        .then(async (response) => {
          this.graphicData.popupTemplate.content = ''
          this.geocode = response.attributes
          await store.commit(
            'held/what3wordsAddressMutator',
            response.attributes.Match_addr
          )
        })
        .catch(() => {
          this.geocode = {}
        })
    },
    async reverseAddressOnClick (Point, SpatialReference, lat, lng, event) {
      const locatorUrl = 'http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer'
      const clickedPoint = new Point({
        latitude: lat,
        longitude: lng,
        spatialReference: new SpatialReference({ wkid: 4326 })
      })
      this.locator
        .locationToAddress(locatorUrl, {
          location: clickedPoint
        })
        .then(async (response) => {
          this.view.popup.content = `<h3 style="color: white">Address: ${response.attributes.Address}</h3><h3 style="color: white">Addr type: ${response.attributes.Addr_type}</h3><h3 style="color: white">Match addr: ${response.attributes.Match_addr}</h3><h3 style="color: white">Add Num: ${response.attributes.AddNum}</h3><h3 style="color: white">Postal: ${response.attributes.Postal}</h3><h3 style="color: white">Region: ${response.attributes.Region}</h3>`
          this.view.popup.open({
            shouldFocus: false,
            location: event.mapPoint
          })
          return response
        })
        .catch(() => {
          this.geocode = {}
        })
    },
    sendRequest () {
      this.esriRequest('https://arcgis.what3words.com/v2/arcgis/tokens', {
        method: 'get',
        query: {
          f: 'json',
          username: process.env.VUE_APP_ESRI_WHAT_3_WORDS_EMAIL,
          password: process.env.VUE_APP_ESRI_WHAT_3_WORDS_PASS,
          expiration: 10
        }
      }).then((response) => {
        const locatorUrl =
          'https://arcgis.what3words.com/v2/arcgis/rest/services/what3words_EN_English/GeocodeServer?token=' +
          response.data.token
        const point = {
          type: 'point',
          longitude: parseFloat(this.heldGetter.lng),
          latitude: parseFloat(this.heldGetter.lat)
        }
        const params = {
          location: point
        }
        this.convertTo3WordAddress(params, locatorUrl)
      })
    },
    convertTo3WordAddress (params, locatorUrl) {
      this.locator
        .locationToAddress(locatorUrl, params)
        .then((response) => {
          this.graphicData.popupTemplate.content = ''
          this.what3words = response.address
        })
        .catch(() => {
          this.what3words = ''
        })
    },
    _sendToFirestore (data) {
      if (!this.map) return

      data.__uniq__ = crypto.randomUUID()

      this.map.set({ data: JSON.stringify(data) })
    },
    async createMap () {
      if (!this.map) this.map = await this.$firestore.collection('map').doc()

      this.map.set({ userID: this.authUserGetter.id })
    },
    decimalToDMS (decimalValue) {
      const degrees = Math.floor(decimalValue)

      const decimalMinutes = (decimalValue - degrees) * 60

      const minutes = Math.floor(decimalMinutes)

      const seconds = (decimalMinutes - minutes) * 60

      return `${degrees}° ${minutes}' ${seconds.toFixed(2)}"`
    },
    dmsFormat (latitude, longitude) {
      const latDMS = this.decimalToDMS(Math.abs(latitude))

      const lonDMS = this.decimalToDMS(Math.abs(longitude))

      const latDir = latitude >= 0 ? 'N' : 'S'

      const lonDir = longitude >= 0 ? 'E' : 'W'

      return `${latDMS} ${latDir}, ${lonDMS} ${lonDir}`
    },
    // map filtration's
    setBaseMapsSwitcher (BasemapGallery, Expand, LocalBasemapsSource, Basemap) {
      const basemaps = [
        Basemap.fromId('dark-gray'),
        Basemap.fromId('dark-gray-vector'),
        Basemap.fromId('gray'),
        Basemap.fromId('gray-vector'),
        Basemap.fromId('hybrid'),
        Basemap.fromId('national-geographic'),
        Basemap.fromId('oceans'),
        Basemap.fromId('osm'),
        Basemap.fromId('satellite'),
        Basemap.fromId('streets'),
        Basemap.fromId('streets-navigation-vector'),
        Basemap.fromId('streets-night-vector'),
        Basemap.fromId('streets-relief-vector'),
        Basemap.fromId('streets-vector'),
        Basemap.fromId('terrain'),
        Basemap.fromId('topo'),
        Basemap.fromId('topo-vector')
      ]
      const source = new LocalBasemapsSource({
        basemaps: basemaps
      })
      const basemapGallery = new BasemapGallery({
        view: this.view,
        container: document.createElement('div'),
        source: source
      })
      const bgExpand = new Expand({
        view: this.view,
        content: basemapGallery
      })
      this.view.ui.add(bgExpand, 'top-right')
    },
    displayLayers (FeatureLayer, url, layerName) {
      if (layerName === 'policeDepartments' || layerName === 'fireStations' || layerName === 'hospitals') {
        this.displayFireEMSPD(FeatureLayer, url, layerName)
        return
      }
      const layerObject = {
        url: url
      }
      if (this.getGlobalSetting('ESRI_MAP_REGION')?.value.text && layerName === 'PSAP911') {
        layerObject.definitionExpression = this.getGlobalSetting('ESRI_MAP_REGION')?.value.text
      }

      this.mapFilters[layerName] = new FeatureLayer(layerObject)
      this.view.map.add(this.mapFilters[layerName])
    },
    displayFireEMSPD (FeatureLayer, url, layerName) {
      this.mapFilters[layerName] = new FeatureLayer({
        url: url,
        minScale: 5000000,
        title: `${layerName}`,
        renderer: {
          type: 'simple',
          symbol: {
            type: 'picture-marker',
            width: 16,
            height: 16,
            url: `media/images/${layerName}.png`
          }
        }
      })
      this.view.map.add(this.mapFilters[layerName])
    },
    removeLayers (layerName) {
      this.view.map.remove(this.mapFilters[layerName])
      this.mapFilters[layerName] = null
    }
  }
}
